import {
  darkTheme,
  RemoteAuthenticationIntent,
  themeColor,
  useTracker
} from '@sfstudios/shapeshifter';
import React, { ReactNode, useCallback, useEffect } from 'react';
import { Redirect, Route, Router, Switch, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AppExiter } from './components/AppExiter';
import { CollectionCardLazy } from './components/cards/MediaCards/CollectionCard/CollectionCardLazy';
import { MovieCardLazy } from './components/cards/MediaCards/MovieCard/MovieCardLazy';
import { SeriesCardLazy } from './components/cards/MediaCards/SeriesCard/SeriesCardLazy';
import { ErrorBoundaryWrapper } from './components/ErrorBoundaryWrapper';
import { FocusContextProvider } from './components/Focusable';
import { Layout } from './components/Layout';
import { FixedLoader } from './components/Loader';
import { NetworkOfflinePrompt } from './components/modals/NetworkOfflinePrompt';
import { ServiceAvailabilityModal } from './components/modals/ServiceAvailabilityModal/ServiceAvailabilityModal';
import { useServiceAvailabilityModal } from './components/modals/ServiceAvailabilityModal/useServiceAvailabilityModal';
import { ContentCollectionLazy } from './components/pages/ContentCollectionLazy';
import { ContentCollectionListLazy } from './components/pages/ContentCollectionListLazy';
import { LoginLazy } from './components/pages/LoginLazy';
import More from './components/pages/More';
import { MyAccountLazy } from './components/pages/MyAccountLazy';
import RangeFilterLazy from './components/pages/RangeFilter/RangeFilterLazy';
import SearchFilterLazy from './components/pages/Search/SearchFilterLazy';
import SearchLazy from './components/pages/Search/SearchLazy';
import { SmartTvProfiler } from './components/profiler/SmartTvProfiler';
import { FocusProvider } from './focus-engine/useFocusEngine';
import { getDirection } from './hooks/useKeyListener';
import { CardRoutes, LocationState, PageRoutes } from './types';
import { OpenLinkDrawer } from './utils/drawer/OpenLinkDrawer';
import { history } from './utils/historySingleton';
import { getPathname } from './utils/navigation';

const Wrapper = styled.div`
  background: ${themeColor('ui900')};
`;

const Track = () => {
  const { track } = useTracker();

  useEffect(() => {
    const unlisten = history.listen((location) => {
      track('routeChange', {
        pathname: location.pathname,
        pageTitle: undefined
      });
    });
    return () => {
      if (unlisten) {
        unlisten();
      }
    };
  }, [track]);

  return null;
};

const Loader = FixedLoader;

export const Routes = React.memo(() => {
  return (
    <ErrorBoundaryWrapper>
      <Wrapper>
        <Router history={history}>
          <SmartTvProfiler>
            <FocusEngine>
              <Track />
              <AppExiter />
              <ErrorBoundaryWrapper>
                <PageSwitch />
              </ErrorBoundaryWrapper>
              <ErrorBoundaryWrapper>
                <CardSwitch />
              </ErrorBoundaryWrapper>
              <NetworkOfflinePrompt />
              <OpenLinkDrawer />
            </FocusEngine>
          </SmartTvProfiler>
        </Router>
      </Wrapper>
    </ErrorBoundaryWrapper>
  );
});

const FocusEngine = ({ children }: { children: ReactNode }) => {
  const routeKey = useLocation().key!;
  const getRouteKey = useCallback(() => routeKey, [routeKey]);
  return (
    <FocusProvider getDirection={getDirection} getRouteKey={getRouteKey}>
      {children}
    </FocusProvider>
  );
};

const PageSwitch = () => {
  const location = useLocation<LocationState>();

  const { messageToShow, dismiss } = useServiceAvailabilityModal();

  const renderingBehindCard = !!location.state?.page;
  const pageSwitchLocation = renderingBehindCard
    ? {
        ...location,
        ...location.state.page
      }
    : location;

  return (
    <>
      <ServiceAvailabilityModal dismiss={dismiss} message={messageToShow} />
      <FocusContextProvider value={!renderingBehindCard && !messageToShow}>
        <div style={renderingBehindCard ? { display: 'none' } : {}}>
          <Switch location={pageSwitchLocation}>
            <Route exact path={PageRoutes.Search}>
              <Layout>
                <React.Suspense fallback={<Loader />}>
                  <SearchLazy />
                </React.Suspense>
              </Layout>
            </Route>
            <Route exact path={PageRoutes.SearchFilter}>
              <Layout withPadding={false}>
                <React.Suspense fallback={<Loader />}>
                  <SearchFilterLazy />
                </React.Suspense>
              </Layout>
            </Route>
            <Route exact path={PageRoutes.RangeFilter}>
              <Layout>
                <React.Suspense fallback={<Loader />}>
                  <RangeFilterLazy />
                </React.Suspense>
              </Layout>
            </Route>
            <Route exact path={PageRoutes.ContentCollection}>
              <Layout>
                <React.Suspense fallback={<Loader />}>
                  <ContentCollectionLazy />
                </React.Suspense>
              </Layout>
            </Route>
            <Route exact path={PageRoutes.List}>
              <Layout>
                <React.Suspense fallback={<Loader />}>
                  <ContentCollectionListLazy />
                </React.Suspense>
              </Layout>
            </Route>
            <Route exact path={PageRoutes.Login}>
              <Layout withPadding={false}>
                <React.Suspense fallback={<Loader />}>
                  <LoginLazy
                    backgroundTint={darkTheme.color.ui800}
                    intent={RemoteAuthenticationIntent.Login}
                  />
                </React.Suspense>
              </Layout>
            </Route>
            <Route exact path={PageRoutes.MyAccount}>
              <Layout>
                <React.Suspense fallback={<Loader />}>
                  <MyAccountLazy />
                </React.Suspense>
              </Layout>
            </Route>
            <Route exact path={PageRoutes.More}>
              <Layout>
                <React.Suspense fallback={<Loader />}>
                  <More />
                </React.Suspense>
              </Layout>
            </Route>
            <Redirect
              exact
              from="/"
              to={getPathname(PageRoutes.ContentCollection, { slug: 'home' })}
            />
          </Switch>
        </div>
      </FocusContextProvider>
    </>
  );
};

const CardSwitch = () => {
  return (
    <FocusContextProvider value={true}>
      <Switch>
        <Route exact path={CardRoutes.MovieCard}>
          <Layout card>
            <React.Suspense fallback={<Loader />}>
              <MovieCardLazy />
            </React.Suspense>
          </Layout>
        </Route>
        <Route exact path={CardRoutes.SeriesCard}>
          <Layout card>
            <React.Suspense fallback={<Loader />}>
              <SeriesCardLazy />
            </React.Suspense>
          </Layout>
        </Route>
        <Route exact path={CardRoutes.SearchCard}>
          <Layout>
            <React.Suspense fallback={<Loader />}>
              <SearchLazy />
            </React.Suspense>
          </Layout>
        </Route>
        <Route exact path={CardRoutes.Collection}>
          <Layout card>
            <React.Suspense fallback={<Loader />}>
              <CollectionCardLazy />
            </React.Suspense>
          </Layout>
        </Route>
      </Switch>
    </FocusContextProvider>
  );
};
