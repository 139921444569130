import {
  createApollo,
  DeviceCapabilitiesHelper as DCH,
  DeviceInterface,
  GraphqlServerEnv,
  GraphqlServerEnvName,
  webStorage
} from '@sfstudios/shapeshifter';
import { brand } from './brand';
import { getEnvVariable } from './getEnvVariable';

export const getClient = async (device: DeviceInterface) => {
  return (
    await createApollo({
      brand,
      env: graphqlEnv(),
      useGETForHashedQueries: true,
      connectToDevTools: process.env.NODE_ENV === 'development',
      deviceHeaders: device,
      storage: webStorage,
      cacheInvalidation: { enabled: true },
      persistance: { enabled: false },
      deviceCapabilities: new DCH({
        initial: DCH.getDefaultBrowserCapabilities()
      })
    })
  ).client;
};

const graphqlEnv = (): GraphqlServerEnv => {
  const envName = getEnvVariable('REACT_APP_ENV_NAME', 'production') as
    | GraphqlServerEnvName
    | 'custom';

  if (envName === 'custom') {
    const GRAPHQL_HOST = getEnvVariable(
      'REACT_APP_GRAPHQL_HOST',
      'https://gql.sfanytime.com/graphql'
    );
    const GRAPHQL_WS = getEnvVariable(
      'REACT_APP_GRAPHQL_WS_HOST',
      'wss://ws.sfanytime.com/subscriptions'
    );
    return {
      name: 'custom',
      uri: GRAPHQL_HOST,
      wsUri: GRAPHQL_WS
    };
  }
  return envName;
};
