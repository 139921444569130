import {
  themeColor,
  useAsset,
  useL10n,
  useMenu
} from '@sfstudios/shapeshifter';
import classNames from 'classnames';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useIsCursorVisible } from '../../context/MouseContext';
import { useHover } from '../../hooks/useHover';
import { useKeyListener } from '../../hooks/useKeyListener';
import { fontSize, whiteSpace } from '../../theme';
import { AnchorProps, LocationState } from '../../types';
import { history } from '../../utils/historySingleton';
import { isLowEnd } from '../../utils/platform';
import { FocusContext, Focusable } from '../Focusable';
import Icon, { IconName } from '../Icon';
import { resolvePath } from '../../utils/navigation';
import { T } from '@sfstudios/shapeshifter';

export const MENU_WIDTH_PX: number = 96;

export const FilterProps = ({
  focused,
  ...rest
}: { focused?: boolean } & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLElement
>) => <nav {...rest} />;

const NavIconStyles = styled(Icon)`
  width: 32px;
  height: 32px;
`;
export const NavIcon = React.memo(({ name }: { name: IconName }) => {
  return <NavIconStyles name={name} />;
});

export const NavText = styled.div`
  flex: 1;
  margin-left: ${whiteSpace['4']};
  font-size: ${fontSize.base};
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const NavItem = styled.a`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  color: ${themeColor('white', 0.6)};

  border: none;
  border-left: 6px solid transparent;
  padding: 24px 24px 24px 28px;

  ${NavText} {
    font-weight: normal;
  }
  svg {
    fill: ${themeColor('white', 0.6)};
  }
  &.focused {
    border: 4px solid white;
    border-left: 6px solid white;
    padding: 20px 20px 20px 28px;
    ${NavText} {
      font-weight: bold;
    }
  }
  &.active {
    color: ${themeColor('white')};
    svg {
      fill: ${themeColor('white')};
    }
    border-left-color: ${themeColor('cta')};
  }
`;
const NavItemWrapperStyled = styled.div``;
const MenuStyles = styled(FilterProps)`
  width: ${MENU_WIDTH_PX}px;
  min-height: 100%;
  position: fixed;
  z-index: 4;
  left: 0;
  top: 0;
  padding: 3rem 0;
  display: flex;
  ${() => (isLowEnd ? '' : 'transition: width ease 0.1s;')}
  flex-direction: column;
  ${NavText} {
    display: none;
  }
  &.unfocused {
    background: ${themeColor('ui900')};
  }
  &.focused {
    width: 345px;
    ${NavText} {
      display: flex;
    }
  }
  & > ${NavItemWrapperStyled}:nth-child(2) {
    margin-bottom: 2rem;
  }
  & > ${NavItemWrapperStyled}:last-child {
    margin-top: 2rem;
  }
  .logo-wrapper {
    display: flex;
    padding-left: 24px;
    height: 48px;
    margin-top: 4rem;
    margin-bottom: 6rem;
    img {
      height: 48px;
    }
  }
`;

const Backdrop = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: linear-gradient(
    90deg,
    ${themeColor('ui900')} 0%,
    ${themeColor('ui800')} 18.96%,
    ${themeColor('ui900', 0.5)} 100%
  );
`;

const MenuItem = React.memo(
  ({
    focused,
    to,
    active,
    icon,
    close,
    text
  }: {
    focused: boolean;
    to: string;
    active: boolean;
    icon: IconName;
    close: () => void;
    text: T;
  }) => {
    const onClick = useCallback(
      (e: any) => {
        e.preventDefault();
        close();
        history.push(to);
      },
      [close, to]
    );

    const [hoverRef, isHovered] = useHover();

    const isCursorVisible = useIsCursorVisible();
    if (isCursorVisible) {
      focused = isHovered;
    }

    return (
      <NavItemWrapperStyled ref={hoverRef}>
        <Focusable<AnchorProps>
          data-testid={`testid-side-menu-${to}`}
          focused={focused}
          as={NavItem}
          className={classNames({
            active,
            focused
          })}
          href={to}
          onClick={onClick}
        >
          <NavIcon name={icon} />
          {text && <NavText>{text}</NavText>}
        </Focusable>
      </NavItemWrapperStyled>
    );
  }
);

export const Logo = React.memo(({ focus }: { focus: boolean }) => {
  const { url: expanded } = useAsset({ name: 'logoHorizontal', height: 64 });
  const { url: collapsed } = useAsset({ name: 'logoWithoutText', height: 64 });
  return (
    <div className="logo-wrapper">
      <img src={focus ? expanded : collapsed} alt="" />
    </div>
  );
});

export const useMenuRoutes = () => {
  const { items } = useMenu({ type: 'left' });
  const location = useLocation<LocationState>();
  const renderingBehindCard = !!location.state?.page;
  const pageSwitchLocation = renderingBehindCard
    ? {
        ...location,
        ...location.state.page
      }
    : location;

  const activeIndex = items.findIndex((item) =>
    matchPath(pageSwitchLocation.pathname, resolvePath({ item }))
  );

  return { routes: items, activeIndex };
};

export const Menu: React.FC<{ close: () => void }> = React.memo(({ close }) => {
  const [focusedRow, setFocusedRow] = useState(0);
  let focused = useContext(FocusContext);
  const { routes, activeIndex } = useMenuRoutes();
  const { t } = useL10n();

  useEffect(() => {
    setFocusedRow(activeIndex === -1 ? 1 : activeIndex);
  }, [activeIndex, focused]);

  useKeyListener({
    down: () => setFocusedRow((r) => Math.min(r + 1, routes.length - 1)),
    up: () => setFocusedRow((r) => Math.max(0, r - 1)),
    right: close
  });

  const [hoverRef, isHovered] = useHover();

  const isCursorVisible = useIsCursorVisible();

  const menuOpen = focused || (isCursorVisible && isHovered);

  return (
    <div>
      <div ref={hoverRef}>
        <MenuStyles
          className={classNames({ focused: menuOpen, unfocused: !menuOpen })}
        >
          <Logo focus={menuOpen} />
          {routes.map((item, i) => (
            <MenuItem
              to={resolvePath({ item })}
              key={item.id}
              icon={item.icon}
              focused={menuOpen && focusedRow === i}
              active={activeIndex === i}
              close={close}
              text={t(item.titleKey)}
            />
          ))}
        </MenuStyles>
      </div>

      {menuOpen && <Backdrop onClick={close} />}
    </div>
  );
});
