import { T, themeColor, useAsset, useL10n } from '@sfstudios/shapeshifter';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components/macro';
import { FocusGroup } from '../../focus-engine/FocusGroup';
import { history } from '../../utils/historySingleton';
import { isLowEnd } from '../../utils/platform';
import { allUnset, focusWithin } from '../../utils/styleUtils';
import { IconName } from '../Icon';
import { MENU_WIDTH_PX, NavIcon, NavText, useMenuRoutes } from './Menu';
import { resolvePath } from '../../utils/navigation';

const NavItem = styled.button`
  all: unset;
  ${allUnset}
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  color: ${themeColor('white', 0.6)};

  border-left: 6px solid transparent;
  padding: 24px 24px 24px 28px;

  ${NavText} {
    font-weight: normal;
  }
  svg {
    fill: ${themeColor('white', 0.6)};
  }

  ${focusWithin(css`
    border: 4px solid white;
    border-left: 6px solid white;
    padding: 20px 20px 20px 28px;
    ${NavText} {
      font-weight: bold;
    }
  `)}

  &.active {
    color: ${themeColor('white')};
    svg {
      fill: ${themeColor('white')};
    }
    border-left-color: ${themeColor('cta')};
  }
`;

const Backdrop = styled.div.attrs({ className: 'backdrop' })`
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: linear-gradient(
    90deg,
    ${themeColor('ui900')} 0%,
    ${themeColor('ui800')} 18.96%,
    ${themeColor('ui900', 0.5)} 100%
  );
`;

const MenuItem = React.memo(
  ({
    to,
    active,
    icon,
    close,
    text
  }: {
    to: string;
    active: boolean;
    icon: IconName;
    close: () => void;
    text: T;
  }) => {
    const onClick = useCallback(
      (e: any) => {
        e.preventDefault();
        close();
        history.push(to);
      },
      [close, to]
    );

    return (
      <Item
        data-testid={`testid-side-menu-${to}`}
        className={classNames({
          active
        })}
        onClick={onClick}
      >
        <NavIcon name={icon} />
        {text && <NavText>{text}</NavText>}
      </Item>
    );
  }
);

const Item = styled(NavItem)`
  :focus {
    outline: 3px solid white;
  }
`;

type Props = {};

export const Menu2: React.FC<Props> = React.memo((props) => {
  const { t } = useL10n();
  const { routes, activeIndex } = useMenuRoutes();
  const { url: expanded } = useAsset({ name: 'logoHorizontal', height: 64 });
  const { url: collapsed } = useAsset({ name: 'logoWithoutText', height: 64 });

  return (
    <Container>
      <FocusGroup id={'menu'} layer="menu" blockMovingUp blockMovingDown>
        <MenuStyles expanded={expanded} collapsed={collapsed}>
          <div className="logo" />
          {routes.map((item, i) => (
            <MenuItem
              to={resolvePath({ item })}
              key={item.id}
              icon={item.icon}
              active={activeIndex === i}
              close={() => {}}
              text={t(item.titleKey)}
            />
          ))}
        </MenuStyles>
      </FocusGroup>
      <Backdrop />
    </Container>
  );
});

const MenuStyles = styled.nav<{ expanded: string; collapsed: string }>`
  width: ${MENU_WIDTH_PX}px;
  min-height: 100%;
  position: fixed;
  z-index: 4;
  left: 0;
  top: 0;
  padding: 3rem 0;
  display: flex;
  ${() => (isLowEnd ? '' : 'transition: width ease 0.1s;')}
  flex-direction: column;
  ${NavText} {
    display: none;
  }
  background: ${themeColor('ui900')};
  .logo {
    background-image: url(${(props) => props.collapsed});
    background-size: 75%;
    background-repeat: no-repeat;
    display: flex;
    margin-left: 24px;
    height: 64px;
    width: 64px;
    margin-top: 4rem;
    margin-bottom: 5rem;
  }

  ${focusWithin(css<{ expanded: string }>`
    .logo {
      background-image: url(${(props) => props.expanded});
      width: 206px;
    }
    width: 345px;
    ${NavText} {
      display: flex;
    }
  `)}

  & > ${Item}:nth-child(2) {
    margin-bottom: 2rem;
  }

  & > ${Item}:last-child {
    margin-top: 2rem;
  }
`;

const Container = styled.div`
  ${focusWithin(css`
    .backdrop {
      display: inherit;
    }
  `)}
`;
