export type LocationState = {
  page?: {
    pathname: string;
    search?: string;
    state: LocationState;
  };
  isMainPage?: boolean;
};

export enum PageRoutes {
  MyAccount = '/my-account',
  More = '/more',
  List = '/list/:slug',
  ContentCollection = '/lists/:slug',
  Login = '/my-account/login',
  Search = '/search',
  SearchFilter = '/search/filter',
  RangeFilter = '/search/range/:id/:type'
}

export enum CardRoutes {
  SearchCard = '/search-card', // we need a search card in addition to a search page to make sure scroll restoration works
  SeriesCard = '/media/series/:slug',
  MovieCard = '/media/movie/:slug',
  Collection = '/collection/:slug'
}

export type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;
export type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;
export type AnchorProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};
