/**
 * Model groups are taken from:
 * https://developer.samsung.com/smarttv/develop/specifications/tv-model-groups.html
 */
import data_ from './samsungModelData.json';
const data = data_ as Record<string, string>;

const modelGroupStringToList = (model: string): string[] =>
  model.split(',').map((s) => s.trim());

const SAMSUNG_TV_MODELS_MAP = {
  /** 2024 */
  TV_2024_PREMIUM1: modelGroupStringToList(data.TV_2024_PREMIUM1),
  TV_2024_PREMIUM2: modelGroupStringToList(data.TV_2024_PREMIUM2),
  TV_2024_PREMIUM4: modelGroupStringToList(data.TV_2024_PREMIUM4),
  TV_2024_PREMIUM6: modelGroupStringToList(data.TV_2024_PREMIUM6),
  TV_2024_STANDARD1: modelGroupStringToList(data.TV_2024_STANDARD1),
  TV_2024_STANDARD2: modelGroupStringToList(data.TV_2024_STANDARD2),
  TV_2024_BASIC2: modelGroupStringToList(data.TV_2024_BASIC2),
  TV_2024_BASIC3: modelGroupStringToList(data.TV_2024_BASIC3),

  /** 2023 */
  TV_2023_PREMIUM1: modelGroupStringToList(data.TV_2023_PREMIUM1),
  TV_2023_PREMIUM2: modelGroupStringToList(data.TV_2023_PREMIUM2),
  TV_2023_PREMIUM3: modelGroupStringToList(data.TV_2023_PREMIUM3),
  TV_2023_PREMIUM4: modelGroupStringToList(data.TV_2023_PREMIUM4),
  TV_2023_STANDARD1: modelGroupStringToList(data.TV_2023_STANDARD1),
  TV_2023_BASIC1: modelGroupStringToList(data.TV_2023_BASIC1),
  TV_2023_BASIC2: modelGroupStringToList(data.TV_2023_BASIC2),
  TV_2023_BASIC3: modelGroupStringToList(data.TV_2023_BASIC3),

  /** 2022 */
  TV_2022_PREMIUM1: modelGroupStringToList(data.TV_2022_PREMIUM1),
  TV_2022_PREMIUM2: modelGroupStringToList(data.TV_2022_PREMIUM2),
  TV_2022_PREMIUM3: modelGroupStringToList(data.TV_2022_PREMIUM3),
  TV_2022_STANDARD1: modelGroupStringToList(data.TV_2022_STANDARD1),
  TV_2022_BASIC1: modelGroupStringToList(data.TV_2022_BASIC1),

  /** 2021 */
  TV_2021_PREMIUM1: modelGroupStringToList(data.TV_2021_PREMIUM1),
  TV_2021_PREMIUM2: modelGroupStringToList(data.TV_2021_PREMIUM2),
  TV_2021_PREMIUM3: modelGroupStringToList(data.TV_2021_PREMIUM3),
  TV_2021_STANDARD1: modelGroupStringToList(data.TV_2021_STANDARD1),
  TV_2021_BASIC1: modelGroupStringToList(data.TV_2021_BASIC1),

  /** 2020 */
  TV_2020_PREMIUM: modelGroupStringToList(data.TV_2020_PREMIUM),
  TV_2020_STANDARD: modelGroupStringToList(data.TV_2020_STANDARD),
  TV_2020_BASIC1: modelGroupStringToList(data.TV_2020_BASIC1),
  TV_2020_BASIC2: modelGroupStringToList(data.TV_2020_BASIC2),

  /** 2019 */
  TV_2019_PREMIUM: modelGroupStringToList(data.TV_2019_PREMIUM),
  TV_2019_STANDARD: modelGroupStringToList(data.TV_2019_STANDARD),
  TV_2019_BASIC1: modelGroupStringToList(data.TV_2019_BASIC1),
  TV_2019_BASIC2: modelGroupStringToList(data.TV_2019_BASIC2),

  /** 2018 */
  TV_2018_PREMIUM: modelGroupStringToList(data.TV_2018_PREMIUM),
  TV_2018_STANDARD1: modelGroupStringToList(data.TV_2018_STANDARD1),
  TV_2018_STANDARD2: modelGroupStringToList(data.TV_2018_STANDARD2),
  TV_2018_STANDARD3: modelGroupStringToList(data.TV_2018_STANDARD3),

  /** 2017 */
  TV_2017_PREMIUM: modelGroupStringToList(data.TV_2017_PREMIUM),
  TV_2017_STANDARD: modelGroupStringToList(data.TV_2017_STANDARD),
  TV_2017_ENTRY_CN1: modelGroupStringToList(data.TV_2017_ENTRY_CN1),

  /** 2016 */
  TV_2016_PREMIUM: modelGroupStringToList(data.TV_2016_PREMIUM),
  TV_2016_STANDARD1: modelGroupStringToList(data.TV_2016_STANDARD1),
  TV_2016_STANDARD2: modelGroupStringToList(data.TV_2016_STANDARD2),

  /** 2015 */
  TV_2015_PREMIUM: modelGroupStringToList(data.TV_2015_PREMIUM),
  TV_2015_STANDARD1: modelGroupStringToList(data.TV_2015_STANDARD1),
  TV_2015_STANDARD2: modelGroupStringToList(data.TV_2015_STANDARD2),
  TV_2015_ENTRY: modelGroupStringToList(data.TV_2015_ENTRY)
};

export type SamsungTVModelGroups = keyof typeof SAMSUNG_TV_MODELS_MAP;

export const SAMSUNG_TV_MODELS = Object.keys(
  SAMSUNG_TV_MODELS_MAP
) as SamsungTVModelGroups[];

export const extractSamsungTVModelGroup = (
  model: string
): SamsungTVModelGroups | null => {
  for (let i = 0; i <= SAMSUNG_TV_MODELS.length; i += 1) {
    const thisModelListKey = SAMSUNG_TV_MODELS[i];
    const thisModelList = SAMSUNG_TV_MODELS_MAP[thisModelListKey];
    if (thisModelList && thisModelList.includes(model)) {
      return thisModelListKey;
    }
  }

  return null;
};
